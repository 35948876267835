import { getStytchClient } from '@lib/stytch/client'
import { StytchB2BProvider } from '@stytch/nextjs/b2b'

const stytch = getStytchClient()

export default function App({ Component, pageProps }) {
  return (
    <>
      <StytchB2BProvider stytch={stytch}>
        <Component {...pageProps} />
      </StytchB2BProvider>
    </>
  )
}
