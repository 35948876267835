import { StytchB2BHeadlessClient } from '@stytch/vanilla-js/b2b/headless'
import { createStytchB2BHeadlessClient } from '@stytch/nextjs/b2b/headless'

export const getStytchClient = (): StytchB2BHeadlessClient => {
  return createStytchB2BHeadlessClient(process.env.STYTCH_PUBLIC_TOKEN, {
    cookieOptions: {
      domain: process.env.STYTCH_COOKIE_DOMAIN,
      opaqueTokenCookieName: 'glisseo_session',
      jwtCookieName: 'glisseo_session_jwt',
    },
  })
}

export const revokeStytchSession = async (
  stytch: StytchB2BHeadlessClient,
): Promise<void> => {
  await stytch.session.revoke()
}
